import React, { useEffect, useState } from 'react';
import axios from '../../../../../../../utils/axios';
import styled from '@emotion/styled';
import Loading from '../../../../../../../components/Loading';
import * as C from '../../../../../../../pages/pages/NumerosMaxion/PlanoOperacional/Versions/NovosProdutos/components/styles';
import UnitSelectAsync from '../../../../../../../components/UnitSelectAsync';
import { Formik } from 'formik';
import { Switch } from '../../../../../../../components/Swtich';
import { Button } from '@mui/material';
import _ from 'lodash';
import { formatNumberFloat } from '../../../../../../../utils/formats';

export default function POReportsProductsDetails({ t, versionId, customerId }) {
  const [filters, setFilters] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowsOriginal, setRowsOriginal] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const TableDiv = styled.div`
    .table-container {
      margin: 20px;
      height: calc(100vh - 405px) !important;
      overflow-y: auto;
    }
    table {
      width: 100%;
      border-collapse: collapse;
    }

    table tr:last-child .border-bottom {
      border-bottom: 2px solid #0783c2;
    }

    th {
      color: #0783c2;
      background-color: #c2ddf0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    th,
    td {
      padding: 10px;
      text-align: center;
      font-size: 14px;
    }

    thead th {
      background-color: #c2ddf0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .row-title th {
      background-color: #f4f7fc;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    tbody tr:nth-child(even) {
      background-color: #f4f8fc;
    }

    .border-left {
      border-left: 2px solid #0783c2;
    }

    .border-right {
      border-right: 2px solid #0783c2;
    }

    .border-top {
      border-top: 2px solid #0783c2;
    }
  `;

  const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  `;

  const getProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/product_details?version_id=${versionId}&sk_grupo_cliente=${customerId}&filters=${filters}`
      )
      .then((response) => {
        setData(response.data);
        setDataOriginal(response.data);

        setRows(response.data.rows);
        setRowsOriginal(response.data.rows);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterData = (data, filters) => {
    return _.filter(data, (item) => {
      return Object.keys(filters).every((key) => {
        const filterValues = filters[key];

        if (Array.isArray(filterValues)) {
          if (filterValues.length === 0) {
            return true;
          }

          return filterValues.some((filter) => item[key] === filter.key);
        }

        if (filterValues === '' || filterValues === false) {
          return true;
        }

        return item[key] === filterValues;
      });
    });
  };

  const initialValues = {
    sk_hierarquia_produto: [],
    sk_segmento: [],
    sk_aplicacao: [],
    sk_organizacao: [],
    volume_diff_pct_vs_market: false,
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <C.Card>
            <C.CardContent>
              <Formik initialValues={initialValues} onSubmit={() => {}}>
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                  setValues,
                }) => (
                  <Form onSubmit={() => {}}>
                    <div className="item">
                      <p>{t('Organização', { ns: 'maxion' })}</p>
                      <div>
                        <UnitSelectAsync
                          limitTags={0}
                          size="small"
                          name="sk_organizacao"
                          value={
                            values.sk_organizacao === ''
                              ? null
                              : values.sk_organizacao
                          }
                          label={t('Organização', { ns: 'maxion' })}
                          loading={isLoading}
                          disabled={isLoading}
                          loadingText="Carregando"
                          options={
                            data && data.filters && data.filters.organizacao
                              ? data.filters.organizacao
                              : []
                          }
                          getOptionLabel={(option) => option.label}
                          onBlur={handleBlur}
                          onChange={(event, value) =>
                            setFieldValue('sk_organizacao', value)
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.key === value.key
                          }
                          disableCloseOnSelect
                          multiple
                        />
                      </div>
                    </div>

                    <div className="item">
                      <p>{t('Hierarquia', { ns: 'maxion' })}</p>
                      <div>
                        <UnitSelectAsync
                          limitTags={0}
                          size="small"
                          name="sk_hierarquia_produto"
                          value={
                            values.sk_hierarquia_produto === ''
                              ? null
                              : values.sk_hierarquia_produto
                          }
                          label={t('Hierarquia', { ns: 'maxion' })}
                          loading={isLoading}
                          disabled={isLoading}
                          loadingText="Carregando"
                          options={
                            data && data.filters && data.filters.hierarquia
                              ? data.filters.hierarquia
                              : []
                          }
                          getOptionLabel={(option) => option.label}
                          onBlur={handleBlur}
                          onChange={(event, value) =>
                            setFieldValue('sk_hierarquia_produto', value)
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.key === value.key
                          }
                          disableCloseOnSelect
                          multiple
                        />

                        {errors.sk_hierarquia_produto &&
                          touched.sk_hierarquia_produto && (
                            <C.TextError>
                              {errors.sk_hierarquia_produto}
                            </C.TextError>
                          )}
                      </div>
                    </div>

                    <div className="item">
                      <p>{t('Segmento', { ns: 'maxion' })}</p>
                      <div>
                        <UnitSelectAsync
                          limitTags={0}
                          size="small"
                          name="sk_segmento"
                          value={
                            values.sk_segmento === ''
                              ? null
                              : values.sk_segmento
                          }
                          label={t('Segmento', { ns: 'maxion' })}
                          loading={isLoading}
                          disabled={isLoading}
                          loadingText="Carregando"
                          options={
                            data && data.filters && data.filters.segmento
                              ? data.filters.segmento
                              : []
                          }
                          getOptionLabel={(option) => option.label}
                          onBlur={handleBlur}
                          onChange={(event, value) =>
                            setFieldValue('sk_segmento', value)
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.key === value.key
                          }
                          disableCloseOnSelect
                          multiple
                        />
                      </div>
                    </div>

                    <div className="item">
                      <p>{t('Aplicação', { ns: 'maxion' })}</p>
                      <div>
                        <UnitSelectAsync
                          limitTags={0}
                          size="small"
                          name="sk_aplicacao"
                          value={
                            values.sk_aplicacao === ''
                              ? null
                              : values.sk_aplicacao
                          }
                          label={t('Aplicação', { ns: 'maxion' })}
                          loading={isLoading}
                          disabled={isLoading}
                          loadingText="Carregando"
                          options={
                            data && data.filters && data.filters.aplicacao
                              ? data.filters.aplicacao
                              : []
                          }
                          getOptionLabel={(option) => option.label}
                          onBlur={handleBlur}
                          onChange={(event, value) =>
                            setFieldValue('sk_aplicacao', value)
                          }
                          isOptionEqualToValue={(option, value) =>
                            option.key === value.key
                          }
                          disableCloseOnSelect
                          multiple
                        />
                      </div>
                    </div>
                    <C.Flag>
                      <p>{t('Diferença de valores', { ns: 'maxion' })}</p>
                      <Switch
                        checked={values.volume_diff_pct_vs_market}
                        value={values.volume_diff_pct_vs_market}
                        onChange={(e) => {
                          setFieldValue(
                            'volume_diff_pct_vs_market',
                            e.target.checked
                          );
                        }}
                      />
                    </C.Flag>
                    <div className={'item'}>
                      <div
                        style={{
                          flexDirection: 'row',
                        }}
                      >
                        <Button
                          color="primary"
                          autoFocus
                          onClick={() => {
                            const filteredData = filterData(
                              rowsOriginal,
                              values
                            );

                            if (filteredData) {
                              setRows(filteredData);
                            }
                          }}
                          type="button"
                          variant="contained"
                          style={{ width: 120 }}
                        >
                          {t('Filtrar', { ns: 'maxion' })}
                        </Button>
                        <Button
                          color="primary"
                          autoFocus
                          onClick={() => {
                            setValues(initialValues);
                            setRows(rowsOriginal);
                          }}
                          type="button"
                          variant="contained"
                          style={{ width: 120, marginLeft: 10 }}
                        >
                          {t('Limpar', { ns: 'maxion' })}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </C.CardContent>
          </C.Card>

          {data.total && (
            <>
              <p>
                <b>{t('Receitas', { ns: 'maxion' })}</b>
                <br />
                {t('Base', { ns: 'maxion' })}: R${' '}
                {data.total.total_value_base.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}{' '}
                | {t('Crítica', { ns: 'maxion' })}: R${' '}
                {data.total.total_value_critic.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}{' '}
                {/*| Base + Ajustes: R${' '}*/}
                {/*{(*/}
                {/*  data.total.total_value_base + data.total.total_value_temp*/}
                {/*).toLocaleString(undefined, {*/}
                {/*  minimumFractionDigits: 2,*/}
                {/*})}*/}
              </p>
            </>
          )}

          <TableDiv>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {data &&
                      data.header &&
                      data.header.length > 0 &&
                      data.header.map((itemHeader, idxHeader) => (
                        <th
                          key={`header-${idxHeader}`}
                          colSpan={
                            itemHeader === 'AOP + Crítica' ||
                            itemHeader === 'Ajustes' ||
                            itemHeader === 'Porcentagem de crescimento'
                              ? '3'
                              : '2'
                          }
                          className={'border-left border-top border-right'}
                        >
                          {t(itemHeader, { ns: 'maxion' })}
                        </th>
                      ))}
                  </tr>
                  <tr className={'row-title'}>
                    <th>{t('Produto', { ns: 'maxion' })}</th>
                    <th className={'border-left'}>
                      {t('Total Volume', { ns: 'maxion' })}
                    </th>
                    <th className={'border-right'}>
                      {t('Total Receita', { ns: 'maxion' })}
                    </th>
                    <th>{t('Mercado', { ns: 'maxion' })}</th>
                    <th>{t('Crítica', { ns: 'maxion' })}</th>
                    <th className={'border-right'}>
                      {t('Ajustes', { ns: 'maxion' })}
                    </th>
                    <th>{t('Total Volume', { ns: 'maxion' })}</th>
                    <th className={'border-right'}>
                      {t('Produto', { ns: 'maxion' })}
                    </th>
                    <th>{t('Total Volume', { ns: 'maxion' })}</th>
                    <th>{t('Preço médio', { ns: 'maxion' })}</th>
                    <th className={'border-right'}>
                      {t('Produto', { ns: 'maxion' })}
                    </th>
                    <th>{t('Total Volume', { ns: 'maxion' })}</th>
                    <th>{t('Preço médio', { ns: 'maxion' })}</th>
                    <th className={'border-right'}>
                      {t('Produto', { ns: 'maxion' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows &&
                    rows.length > 0 &&
                    rows.map((item, idx) => (
                      <tr key={`prd-${idx}`}>
                        <td>{item.descricao_produto}</td>
                        <td className={`border-left border-bottom`}>
                          {formatNumberFloat(item.total_volume_base)}
                        </td>
                        <td className={`border-right  border-bottom`}>
                          {formatNumberFloat(item.total_value_base)}
                        </td>

                        <td className={'border-bottom'}>
                          {formatNumberFloat(item.pct_evolution_market) || 0}%
                        </td>
                        <td className={'border-bottom'}>
                          {formatNumberFloat(item.pct_critic) || 0}%
                        </td>
                        <td className={'border-right border-bottom'}>
                          {formatNumberFloat(item.pct_ajuste) || 0}%
                        </td>

                        <td className={'border-bottom'}>
                          {formatNumberFloat(item.total_volume_market)}
                        </td>
                        <td className={'border-right border-bottom'}>
                          {formatNumberFloat(item.total_value_market)}
                        </td>

                        <td className={'border-bottom'}>
                          {formatNumberFloat(item.total_volume_critic)}
                        </td>
                        <td className={'border-bottom'}>
                          {formatNumberFloat(item.unitary_value_critic)}
                        </td>
                        <td className={'border-right border-bottom'}>
                          {formatNumberFloat(item.total_value_critic)}
                        </td>

                        <td className={'border-bottom'}>
                          {formatNumberFloat(item.total_volume_ajuste)}
                        </td>
                        <td className={'border-bottom'}>
                          {formatNumberFloat(item.unitary_value_ajuste)}
                        </td>
                        <td className={'border-bottom border-right'}>
                          {formatNumberFloat(item.total_value_ajuste)}
                        </td>
                      </tr>
                    ))}
                  {rows && rows.length == 0 && (
                    <tr>
                      <td></td>
                      <td
                        colSpan={13}
                        className={
                          'border-left border-top border-right border-bottom'
                        }
                      >
                        {t('Sem itens', { ns: 'maxion' })}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </TableDiv>
        </>
      )}
    </>
  );
}
