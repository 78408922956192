import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import axios from '../../../../../../../utils/axios';
import useAuth from '../../../../../../../hooks/useAuth';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { POContext } from '../../../../../../../contexts/POContext';
import { checkPermission } from '../../../../../../../utils/permissions';
import SelectAsync from '../../../../../../../components/UnitSelectAsync';
import { Button, Divider, Checkbox, CircularProgress } from '@mui/material';
import ResultsFiltered from './ResultsFiltered';
import ModalDescartCritic from './ModalDescartCritic';

const Box = styled.div`
  padding-top: 30px;

  form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    padding-bottom: 20px;
    max-width: 1500px;

    .item {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-size: 16px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.blue_200};
        min-width: 213px;
      }

      @media (max-width: 1700px) {
        flex-direction: column;
        align-items: normal;
        gap: 10px;
      }
    }

    .div-checkboxs {
      display: flex;
      width: 100%;
      gap: 20px;
      justify-content: end;

      .checkbox {
        display: flex;
        align-items: center;
        gap: 3px;

        p {
          font-size: 16px;
          font-weight: 400;
          color: ${({ theme }) => theme.colors.blue_200};
        }
      }
    }
  }

  .divider {
    margin: 20px 0;
  }

  .text-no-products {
    display: block;
    font-size: 25px;
    padding: 50px 0;
    color: ${({ theme }) => theme.colors.gray_500};
  }
`;

const TextError = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
`;

export default function Filters({ t }) {
  const [data, setData] = useState(null);
  const [openModalDescartCritic, setOpenModalDescartCritic] = useState(false);

  const [hierarquias, setHierarquias] = useState([]);
  const [organizacoes, setOrganizacoes] = useState([]);
  const [segmentos, setSegmentos] = useState([]);
  const [aplicacoes, setAplicacoes] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [produtos, setProdutos] = useState([]);

  const [loadingHierarquias, setLoadingHierarquias] = useState(false);
  const [loadingOrganizacoes, setLoadingOrganizacoes] = useState(false);
  const [loadingSegmentos, setLoadingSegmentos] = useState(false);
  const [loadingAplicacoes, setLoadingAplicacoes] = useState(false);
  const [loadingClientes, setLoadingClientes] = useState(false);
  const [loadingProdutos, setLoadingProdutos] = useState(false);
  const [oldMetricaPO, setOldMetricaPO] = useState(null);

  const { user } = useAuth();
  const {
    version,
    customer,
    metricaPO,
    isPlanActive,
    criticDetails,
    setIsNewProductOrOnlyNew,
    cleanCriticDetails,
    isSave,
    setIsSave,
  } = useContext(POContext);

  const finishedFields =
    customer &&
    (customer.calendar === 'finished' ||
      customer.segmentation === 'finished' ||
      customer.aop === 'finished');

  const userHasPermissionToManage = user && checkPermission(user, [], [9, 10]);

  const customerField = `operational_plan_${metricaPO}`;

  const isDisabledManage =
    !userHasPermissionToManage ||
    !isPlanActive ||
    !finishedFields ||
    customer?.[customerField] === 'finished';

  const initialValues = {
    hierarquias: [],
    segmentos: [],
    aplicacoes: [],
    organizacoes: [],
    clientes: [],
    produtos: [],
    checkSemValor: false,
    checkSomenteNovos: false,
  };

  const validationSchema = Yup.object().shape({
    organizacoes: Yup.array().min(1, 'Selecione pelo menos um'),
  });

  useEffect(() => {
    if (oldMetricaPO !== null && metricaPO !== oldMetricaPO) {
      setData(null);
    }

    setOldMetricaPO(metricaPO);
  }, [metricaPO, oldMetricaPO]);

  const fetchProdutos = useCallback(async () => {
    try {
      setLoadingProdutos(true);

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}criticas/produtos_filtrados`,
        {
          base_date: version.base_date,
          sk_grupo_cliente: customer.sk_grupo_cliente,
        }
      );

      const newArray = data.map((item) => {
        return {
          ...item,
          descricao_produto: `${item.codigo_produto} - ${item.descricao_produto}`,
        };
      });

      setProdutos(newArray);
    } catch (error) {
      console.log(`Erro fetching produtos:  ${error}`);
    } finally {
      setLoadingProdutos(false);
    }
  }, [version, customer]);

  const fetchClientesSAP = useCallback(async () => {
    try {
      setLoadingClientes(true);

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}criticas/clientes_filtrados`,
        {
          base_date: version.base_date,
          sk_grupo_cliente: customer.sk_grupo_cliente,
        }
      );

      setClientes(data);
    } catch (error) {
      console.log(`Erro fetching clientes:  ${error}`);
    } finally {
      setLoadingClientes(false);
    }
  }, [version, customer]);

  useEffect(() => {
    if (version && customer) {
      fetchHierarquias();
      fetchOrganizacoes();
      fetchSegmentos();
      fetchAplicacoes();
      fetchClientesSAP();
      fetchProdutos();
    }
  }, [version, customer, fetchClientesSAP, fetchProdutos]);

  //Buscando hierarquias
  async function fetchHierarquias() {
    try {
      setLoadingHierarquias(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}criticas/hierarquia-produtos`
      );

      const newArray = data.map((item) => {
        return {
          ...item,
          descricao_hierarquia_produto: `${item.codigo_hierarquia_produto} - ${item.descricao_hierarquia_produto}`,
        };
      });

      setHierarquias(newArray);
    } catch (error) {
      console.log(`Erro fetching hierarquiais:  ${error}`);
    } finally {
      setLoadingHierarquias(false);
    }
  }

  //Buscando organizações
  async function fetchOrganizacoes() {
    try {
      setLoadingOrganizacoes(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}criticas/organizacoes`
      );

      const newArray = data.map((item) => {
        return {
          ...item,
          descricao_organizacao: `${item.codigo_organizacao} - ${item.descricao_organizacao}`,
        };
      });

      setOrganizacoes(newArray);
    } catch (error) {
      console.log(`Erro fetching organizações:  ${error}`);
    } finally {
      setLoadingOrganizacoes(false);
    }
  }

  //Buscando segmentos
  async function fetchSegmentos() {
    try {
      setLoadingSegmentos(true);

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}criticas/segmentos`
      );

      const newArray = data.map((item) => {
        return {
          ...item,
          descricao_segmento: `${item.codigo_segmento} - ${item.descricao_segmento}`,
        };
      });

      setSegmentos(newArray);
    } catch (error) {
      console.log(`Erro fetching segmentos:  ${error}`);
    } finally {
      setLoadingSegmentos(false);
    }
  }

  //Buscando aplicações
  async function fetchAplicacoes() {
    try {
      setLoadingAplicacoes(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}criticas/aplicacoes`
      );

      const newArray = data.map((item) => {
        return {
          ...item,
          descricao_aplicacao: `${item.codigo_aplicacao} - ${item.descricao_aplicacao}`,
        };
      });

      setAplicacoes(newArray);
    } catch (error) {
      console.log(`Erro fetching aplicações:  ${error}`);
    } finally {
      setLoadingAplicacoes(false);
    }
  }

  function transformDataVolume(data) {
    const newArray = data.map((item) => {
      const baseValues = [];
      const criticaValues = [];
      const reajusteValues = [];

      for (let i = 0; i <= 11; i++) {
        const key = `m${i.toString().padStart(2, '0')}`;
        const baseValue = item[`volume_base_${key}`];
        const criticaValue = item[`volume_critic_${key}`];

        baseValues.push(baseValue);
        criticaValues.push(criticaValue);
        reajusteValues.push(criticaValue);
      }

      return {
        checked: false,
        operational_plan_agg_id: item.operational_plan_agg_id,
        descricao_produto: item.descricao_produto,
        codigo_cliente: item.codigo_cliente,
        nome_cliente: item.nome_cliente,
        volume_total_base: item.original_volume_total_base,
        original_market_pct_evolution: item.original_market_pct_evolution,
        volume_total_market: item.volume_total_market,
        volume_total_critic: item.volume_total_critic,
        baseValues,
        criticaValues,
        reajusteValues,
        reajusteIndexMonths: [],
      };
    });

    return newArray;
  }

  function transformDataPreco(data) {
    const newArray = data.map((item) => {
      const baseValues = [];
      const criticaValues = [];
      const reajusteValues = [];

      for (let i = 0; i <= 11; i++) {
        const key = `m${i.toString().padStart(2, '0')}`;
        const baseValue = item[`unitary_value_base_${key}`];
        const criticaValue = item[`unitary_value_critic_${key}`];

        baseValues.push(baseValue);
        criticaValues.push(criticaValue);
        reajusteValues.push(criticaValue);
      }

      return {
        checked: false,
        operational_plan_agg_id: item.operational_plan_agg_id,
        descricao_produto: item.descricao_produto,
        codigo_cliente: item.codigo_cliente,
        nome_cliente: item.nome_cliente,
        baseValues,
        criticaValues,
        reajusteValues,
        reajusteIndexMonths: [],
      };
    });

    return newArray;
  }

  async function handleFilter(values, props) {
    try {
      setData(null);

      const url = metricaPO === 'volume' ? 'get_op_volume' : 'get_op_value';
      const baseUrl = `${process.env.REACT_APP_PUBLIC_API}po_operational_plan/${url}?filters=version_id=${version.id},sk_grupo_cliente=${customer.sk_grupo_cliente}`;
      const isValueZero =
        metricaPO === 'volume' ? 'is_volume_zero' : 'is_value_zero';

      const filters = [
        { key: 'sk_organizacao', values: values.organizacoes },
        { key: 'sk_hierarquia_produto', values: values.hierarquias },
        { key: 'sk_segmento', values: values.segmentos },
        { key: 'sk_aplicacao', values: values.aplicacoes },
        { key: 'sk_produto', values: values.produtos },
        { key: 'sk_cliente', values: values.clientes },
      ];

      if (!values.checkSemValor && !values.checkSomenteNovos) {
        filters.push(
          { key: `${isValueZero}`, values: values.checkSemValor },
          { key: 'is_new_product', values: values.checkSomenteNovos }
        );
      } else if (values.checkSemValor === true) {
        filters.push({
          key: `${isValueZero}`,
          values: values.checkSemValor,
        });
      } else if (values.checkSomenteNovos === true) {
        filters.push({
          key: 'is_new_product',
          values: values.checkSomenteNovos,
        });
      }

      if (values.checkSemValor || values.checkSomenteNovos) {
        setIsNewProductOrOnlyNew(true);
      } else {
        setIsNewProductOrOnlyNew(false);
      }

      let filterQuery = baseUrl;

      filters.forEach((filter) => {
        if (filter.values !== undefined && filter.values !== null) {
          if (Array.isArray(filter.values) && filter.values.length > 0) {
            const formattedValues = filter.values
              .map((value) => value.sk)
              .join('|');

            filterQuery += `,${filter.key}=[${formattedValues}]`;
          } else if (typeof filter.values === 'boolean') {
            filterQuery += `,${filter.key}=${filter.values}`;
          }
        }
      });

      const { data } = await axios.get(filterQuery);

      const dataVolume = transformDataVolume(data);
      const dataPreco = transformDataPreco(data);
      const newData = metricaPO === 'volume' ? dataVolume : dataPreco;
      setData(newData);
    } catch (error) {
      console.log(error);
      toast.error(t('Erro ao filtrar produtos', { ns: 'maxion' }));
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleFilter,
  });

  const {
    values,
    touched,
    errors,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleSubmit,
  } = formik;

  useEffect(() => {
    if (isSave) {
      handleSubmit();
      setIsSave(false);
    }
  }, [isSave, setIsSave, handleSubmit]);

  if (!userHasPermissionToManage) {
    return null;
  }

  return (
    <Box>
      <form>
        <div className="item">
          <p>{t('Selecione as organizações', { ns: 'maxion' })} *</p>
          <div>
            <SelectAsync
              sx={{ width: 260 }}
              disabled={isDisabledManage}
              multiple
              limitTags={0}
              size="small"
              name="organizacoes"
              value={values.organizacoes}
              label={t('Organizações', { ns: 'maxion' })}
              loading={loadingOrganizacoes}
              loadingText={t('Buscando organizações', { ns: 'maxion' })}
              options={organizacoes}
              getOptionLabel={(option) => option.descricao_organizacao}
              onBlur={handleBlur}
              onChange={(event, value) => setFieldValue('organizacoes', value)}
              isOptionEqualToValue={(option, value) => option.sk === value.sk}
              disableCloseOnSelect
            />

            {errors.organizacoes && touched.organizacoes && (
              <TextError>{errors.organizacoes}</TextError>
            )}
          </div>
        </div>

        <div className="item">
          <p>{t('Selecione os clientes SAP', { ns: 'maxion' })}</p>
          <div>
            <SelectAsync
              sx={{ width: 260 }}
              disabled={isDisabledManage}
              multiple
              limitTags={0}
              size="small"
              name="clientes"
              value={values.clientes}
              label={t('Clientes SAP', { ns: 'maxion' })}
              loading={loadingClientes}
              loadingText={t('Buscando clientes SAP', { ns: 'maxion' })}
              options={clientes}
              getOptionLabel={(option) => option.codigo_e_nome_cliente}
              onBlur={handleBlur}
              onChange={(event, value) => setFieldValue('clientes', value)}
              isOptionEqualToValue={(option, value) => option.sk === value.sk}
              disableCloseOnSelect
            />

            {errors.clientes && touched.clientes && (
              <TextError>{errors.clientes}</TextError>
            )}
          </div>
        </div>

        <div className="item">
          <p style={{ display: 'flex' }}>
            {t('Selecione os segmentos', { ns: 'maxion' })}
          </p>

          <div>
            <SelectAsync
              disabled={isDisabledManage}
              sx={{ width: 260 }}
              multiple
              limitTags={0}
              size="small"
              name="segmentos"
              value={values.segmentos}
              label={t('Segmentos', { ns: 'maxion' })}
              loading={loadingSegmentos}
              loadingText={t('Buscando segmentos', { ns: 'maxion' })}
              options={segmentos}
              getOptionLabel={(option) => option.descricao_segmento}
              onBlur={handleBlur}
              onChange={(event, value) => setFieldValue('segmentos', value)}
              isOptionEqualToValue={(option, value) => option.sk === value.sk}
              disableCloseOnSelect
            />

            {errors.segmentos && touched.segmentos && (
              <TextError>{errors.segmentos}</TextError>
            )}
          </div>
        </div>

        <div className="item">
          <p>{t('Selecione as aplicações', { ns: 'maxion' })}</p>
          <div>
            <SelectAsync
              sx={{ width: 260 }}
              disabled={isDisabledManage}
              multiple
              limitTags={0}
              size="small"
              name="aplicacoes"
              value={values.aplicacoes}
              label={t('Aplicações', { ns: 'maxion' })}
              loading={loadingAplicacoes}
              loadingText={t('Buscando aplicações', { ns: 'maxion' })}
              options={aplicacoes}
              getOptionLabel={(option) => option.descricao_aplicacao}
              onBlur={handleBlur}
              onChange={(event, value) => setFieldValue('aplicacoes', value)}
              isOptionEqualToValue={(option, value) => option.sk === value.sk}
              disableCloseOnSelect
            />

            {errors.aplicacoes && touched.aplicacoes && (
              <TextError>{errors.aplicacoes}</TextError>
            )}
          </div>
        </div>

        <div className="item">
          <p style={{ display: 'flex' }}>
            {t('Selecione as hierarquias', { ns: 'maxion' })}
          </p>

          <div>
            <SelectAsync
              sx={{ width: 260 }}
              disabled={isDisabledManage}
              multiple
              limitTags={0}
              size="small"
              name="hierarquias"
              value={values.hierarquias}
              label={t('Hierarquias', { ns: 'maxion' })}
              loading={loadingHierarquias}
              loadingText={t('Buscando hierarquias', { ns: 'maxion' })}
              options={hierarquias}
              getOptionLabel={(option) => option.descricao_hierarquia_produto}
              onBlur={handleBlur}
              onChange={(event, value) => setFieldValue('hierarquias', value)}
              isOptionEqualToValue={(option, value) => option.sk === value.sk}
              disableCloseOnSelect
            />

            {errors.hierarquias && touched.hierarquias && (
              <TextError>{errors.hierarquias}</TextError>
            )}
          </div>
        </div>

        <div className="item">
          <p>{t('Selecione os produtos', { ns: 'maxion' })}</p>
          <div>
            <SelectAsync
              sx={{ width: 260 }}
              disabled={isDisabledManage}
              multiple
              limitTags={0}
              size="small"
              name="produtos"
              value={values.produtos}
              label={t('Produtos', { ns: 'maxion' })}
              loading={loadingProdutos}
              loadingText={t('Buscando produtos', { ns: 'maxion' })}
              options={produtos}
              getOptionLabel={(option) => option.descricao_produto}
              onBlur={handleBlur}
              onChange={(event, value) => setFieldValue('produtos', value)}
              isOptionEqualToValue={(option, value) => option.sk === value.sk}
              disableCloseOnSelect
            />

            {errors.produtos && touched.produtos && (
              <TextError>{errors.produtos}</TextError>
            )}
          </div>
        </div>

        <div className="div-checkboxs">
          <div className="checkbox">
            <p>{t('Sem valor', { ns: 'maxion' })}</p>

            <Checkbox
              size="small"
              checked={values.checkSemValor}
              disabled={isDisabledManage}
              onChange={(event) => {
                if (values.checkSomenteNovos === true) {
                  setFieldValue('checkSomenteNovos', false);
                }

                setFieldValue('checkSemValor', event.target.checked);
              }}
            />
          </div>

          <div className="checkbox">
            <p>{t('Somente novos', { ns: 'maxion' })}</p>

            <Checkbox
              size="small"
              checked={values.checkSomenteNovos}
              disabled={isDisabledManage}
              onChange={(event) => {
                if (values.checkSemValor === true) {
                  setFieldValue('checkSemValor', false);
                }

                setFieldValue('checkSomenteNovos', event.target.checked);
              }}
            />
          </div>

          <Button
            variant="contained"
            sx={{ width: '170px', height: '40px' }}
            disabled={isSubmitting || isDisabledManage}
            onClick={() => {
              if (criticDetails.length > 0) {
                const text = t(
                  'Há alterações não salvas. Deseja descartar as alterações e filtrar novamente?',
                  { ns: 'maxion' }
                );

                const confirm = window.confirm(text);

                if (confirm) {
                  cleanCriticDetails();
                  handleSubmit();
                }
              } else {
                handleSubmit();
              }
            }}
          >
            {isSubmitting ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              t('Filtrar', { ns: 'maxion' })
            )}
          </Button>
        </div>
      </form>

      <div className="divider">
        <Divider />
      </div>

      {data === null && !isDisabledManage && (
        <b className="text-no-products">
          {t('Realize a filtragem para exibição dos produtos')}
        </b>
      )}

      {data !== null && (
        <ResultsFiltered
          data={data}
          setData={setData}
          loadingData={isSubmitting}
          t={t}
        />
      )}

      {openModalDescartCritic && (
        <ModalDescartCritic
          open={openModalDescartCritic}
          setOpen={setOpenModalDescartCritic}
          onDescart={() => setData(null)}
          t={t}
        />
      )}
    </Box>
  );
}
